import { useEffect, useMemo } from 'react'
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { Auth, Hub } from 'aws-amplify'
import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link'
import { createClient } from 'graphql-ws'
import { useEvent } from 'react-use'
import { Config } from 'src/config'
import { getApiUrl } from 'src/config/urls'
import { isMocking } from 'src/isMocking'

const KEEP_ALIVE_INTERVAL_MS = 10000

function createApolloClient(url: string, region: string) {
  const httpLink = new HttpLink({
    uri: url,
  })

  const getJwtToken = async () => {
    const session = await Auth.currentSession()
    return session.getAccessToken().getJwtToken()
  }

  const auth: AuthOptions = {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: getJwtToken,
  }

  const websocketUrl = url
    .replace('https://', 'wss://')
    .replace('http://', 'ws://')

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    new GraphQLWsLink(
      createClient({
        url: websocketUrl,
        keepAlive: KEEP_ALIVE_INTERVAL_MS,
        retryAttempts: 15,
        shouldRetry: () => true,
        connectionParams: async () => ({
          authentication: await getJwtToken(),
        }),
      })
    ),
    httpLink
  )

  const link = ApolloLink.from([
    createAuthLink({
      url,
      region,
      auth,
    }),
    splitLink,
  ])

  const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        PresenceStatus: {
          merge: true,
        },
      },
    }),
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        pollInterval: 30000,
        skipPollAttempt: () => document.hidden,
      },
    },
    link,
  })

  return client
}

export function useCreateApolloClient({ region }: Config) {
  const graphQLEndpoint = getApiUrl()

  const client = useMemo(() => {
    const graphQLEndpointUrl = isMocking()
      ? 'https://dummy/graphql'
      : graphQLEndpoint

    return createApolloClient(graphQLEndpointUrl, region)
  }, [graphQLEndpoint, region])

  useEffect(() => {
    return () => {
      client.stop()
    }
  }, [client])

  useEvent('visibilitychange', async () => {
    if (document.visibilityState === 'visible') {
      await client.refetchQueries({ include: 'active' })
    }
  })

  useEffect(() => {
    return Hub.listen('auth', async ({ payload: { event } }) => {
      if (event === 'signOut') {
        await client.clearStore()
      }
    })
  }, [client])

  return client
}
