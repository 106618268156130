import { useCallback, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FormField } from 'src/auth/login/FormField'
import { Box } from 'src/elements/Box'
import { Button } from 'src/elements/Button'
import { TitleH2 } from 'src/elements/TitleH2'
import { FormGroup } from 'src/elements/form/FormGroup'
import { gql } from 'src/graphql'
import { CenteredContent } from 'src/layout/CenteredContent'

const StyledTitle = styled(TitleH2)`
  margin-bottom: 40px;
`

const StyledDescription = styled.p`
  margin-bottom: 40px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.blue};
  text-align: center;
`

const StyledBox = styled(Box)`
  width: 100%;
`
const Error = styled.div`
  color: ${(props) => props.theme.colors.red};
  font-size: 14px;
  margin-top: 20px;
`
interface OrganizationInput {
  name: string
}

interface OrganizationViewProps {
  onCompleted?: () => void
}

export function OrganizationView({ onCompleted }: OrganizationViewProps) {
  const { t } = useTranslation()
  const [addOrganization, addOrganizationResult] = useMutation(
    gql(/* GraphQL */ `
      mutation addOrganization($name: String!) {
        addOrganization(name: $name) {
          id
          name
        }
      }
    `),
    {
      refetchQueries: ['permissions'],
      onCompleted,
      onError: () => {
        setError(t('onboarding.error.create'))
      },
    }
  )
  const form = useForm<OrganizationInput>()
  const {
    setFocus,
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  const onSubmit = useCallback(
    async ({ name }: OrganizationInput) => {
      if (addOrganizationResult.loading) {
        return
      }

      await addOrganization({
        variables: {
          name,
        },
      })
    },
    [addOrganization, addOrganizationResult]
  )

  return (
    <CenteredContent withLogo>
      <StyledTitle>{t('onboarding.title')}</StyledTitle>
      <StyledDescription>{t('onboarding.desc')}</StyledDescription>
      <StyledBox>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <FormField
                name="name"
                label={t('onboarding.form.org.name.label')}
                placeholder={t('onboarding.form.org.name.placeholder')}
                requiredMessage={t('onboarding.form.org.name.required')}
                data-test="onboarding-form-name-input"
              />
            </FormGroup>
            <Button
              as="button"
              type="submit"
              label={t('onboarding.form.org.submit')}
              icon={faPlusCircle}
              fullWidth
              data-test="onboarding-submit-button"
              disabled={isSubmitting}
            />
            {error !== null && <Error>{error}</Error>}
          </form>
        </FormProvider>
      </StyledBox>
    </CenteredContent>
  )
}
