import { SyntheticEvent, useCallback } from 'react'
import { faPlug } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from 'src/elements/Button'
import { SubTitle } from 'src/elements/SubTitle'
import { TitleH3 } from 'src/elements/TitleH3'
import { FormGroup } from 'src/elements/form/FormGroup'
import { Input } from 'src/elements/form/Input'
import { Label } from 'src/elements/form/Label'
import { useCode } from './useCode'

const Separator = styled.div`
  &:after {
    content: '-';
    display: block;
    width: 30px;
    text-align: center;
    color: ${(props) => props.theme.colors.blue};
    font-size: 16px;
  }
`

const Wrapper = styled.div`
  max-width: 400px;
`

const CodeWrapper = styled.div`
  display: flex;
  align-items: center;
`

const FormWrapper = styled.form`
  padding-top: 20px;
`

export interface PairingProps {
  screenName: string
  onPair: (code: string) => void
}

export function Pairing({ screenName, onPair }: PairingProps) {
  const { t } = useTranslation()
  const { code, firstProps, secondProps, thirdProps, fourthProps } = useCode()

  const save = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      onPair(code)
    },
    [onPair, code]
  )

  return (
    <Wrapper>
      <TitleH3>{t('screens.pairing.title')}</TitleH3>
      <SubTitle>{t('screens.pairing.instruction')}</SubTitle>
      <FormWrapper onSubmit={save}>
        <FormGroup>
          <Label>{t('screens.pairing.screenLabel')}</Label>
          <Input value={screenName} disabled data-test="screen-name-input" />
        </FormGroup>
        <FormGroup>
          <Label>{t('screens.pairing.codeLabel')}</Label>
          <CodeWrapper data-test="code-wrapper">
            <Input centered inputMode="numeric" {...firstProps} autoFocus />
            <Separator />
            <Input centered inputMode="numeric" {...secondProps} />
            <Separator />
            <Input centered inputMode="numeric" {...thirdProps} />
            <Separator />
            <Input centered inputMode="numeric" {...fourthProps} />
          </CodeWrapper>
        </FormGroup>
        <Button
          as="button"
          fullWidth
          label={t('screens.pairing.activateButton')}
          icon={faPlug}
          type="submit"
        />
      </FormWrapper>
    </Wrapper>
  )
}
