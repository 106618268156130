import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AnimatingModal } from 'src/elements/AnimatingModal'
import { Button } from 'src/elements/Button'
import { FormGroup } from 'src/elements/form/FormGroup'
import { Input } from 'src/elements/form/Input'
import { Label } from 'src/elements/form/Label'
import { gql } from 'src/graphql'
import { useToggleOpener } from 'src/hooks/useToggleOpener'

const Wrapper = styled.div`
  max-width: 400px;
`

const FormWrapper = styled.form`
  padding-top: 20px;
`

interface CreateOrganizationInput {
  name: string
  email: string
}

export function CreateOrganizationButton() {
  const { t } = useTranslation()

  const [createModalOpened, toggleCreateModal, closeCreateModal] =
    useToggleOpener()

  const { handleSubmit, register } = useForm<CreateOrganizationInput>({
    defaultValues: {
      name: '',
      email: '',
    },
  })

  const [addOrganizationWithOwner] = useMutation(
    gql(/* GraphQL */ `
      mutation addOrganizationWithOwner($name: String!, $email: String!) {
        addOrganizationWithOwner(name: $name, email: $email) {
          id
          name
        }
      }
    `),
    {
      refetchQueries: ['allOrganizations'],
      onCompleted: () => {
        closeCreateModal()
      },
    }
  )

  const onFormSubmit = useCallback(
    async ({ name, email }: CreateOrganizationInput) => {
      await addOrganizationWithOwner({
        variables: {
          name,
          email,
        },
      })
    },
    [addOrganizationWithOwner]
  )

  return (
    <>
      <Button
        label={t('organizations.create.button.title')}
        onClick={toggleCreateModal}
      />
      <AnimatingModal
        show={createModalOpened}
        showCloseButton
        onClose={closeCreateModal}
      >
        <Wrapper>
          <FormWrapper onSubmit={handleSubmit(onFormSubmit)}>
            <FormGroup>
              <Label htmlFor="name">{t('organizations.create.name')}</Label>
              <Input {...register('name')} />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="email">{t('organizations.create.email')}</Label>
              <Input {...register('email')} />
            </FormGroup>
            <Button
              as="button"
              fullWidth
              label={t('organizations.create.submit')}
              icon={faPlus}
              type="submit"
            />
          </FormWrapper>
        </Wrapper>
      </AnimatingModal>
    </>
  )
}
