import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useFlag } from '@unleash/proxy-client-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SubTitle } from 'src/elements/SubTitle'
import { TitleH2 } from 'src/elements/TitleH2'
import { gql } from 'src/graphql'
import { ConnectionStatus } from 'src/graphql/graphql'
import { useOrganizationId } from 'src/hooks/useOrganizationId'
import { useScrollToTop } from 'src/hooks/useScrollToTop'
import { ScreenList } from './ScreenList'
import { ScreenListDeprecated } from './ScreenListDeprecated'

const Header = styled.div`
  margin-bottom: 60px;
`

export function Screens() {
  useScrollToTop()

  const organizationId = useOrganizationId()

  const { t } = useTranslation()
  const query = useQuery(
    gql(/* GraphQL */ `
      query getScreensAndSlideshows($organizationId: String!) {
        screens(organizationId: $organizationId) {
          presence {
            status
          }
          ...ScreenListFragment
          ...ScreenListDeprecatedFragment
        }
      }
    `),
    {
      variables: {
        organizationId,
      },
    }
  )

  const screens = query.data?.screens
  const totalCount = screens ? screens.length : 0
  const connectedCount = useMemo(
    () =>
      screens
        ? screens.filter(
            (screen) => screen.presence.status === ConnectionStatus.Connected
          ).length
        : 0,
    [screens]
  )

  const editDisplayPage = useFlag('edit-display-page-240')
  const showScreenStatus = useFlag('show-screen-presence-status-77')

  if (!screens) {
    return null
  }

  return (
    <div>
      <Header>
        <TitleH2>{t('screens.title')}</TitleH2>
        {showScreenStatus ? (
          <SubTitle>
            <>
              {t('screens.subtitle', {
                count: connectedCount,
              })}{' '}
              /{' '}
              {t('screens.subtitle_total', {
                count: totalCount,
              })}
            </>
          </SubTitle>
        ) : (
          <SubTitle>
            {t('screens.subtitle', {
              count: totalCount,
            })}
          </SubTitle>
        )}
      </Header>
      {editDisplayPage ? (
        <ScreenList screens={screens} />
      ) : (
        <ScreenListDeprecated screens={screens} />
      )}
    </div>
  )
}
