import { useQuery } from '@apollo/client'
import { useFlag } from '@unleash/proxy-client-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { device } from 'src/device'
import { SubTitle } from 'src/elements/SubTitle'
import { TitleH2 } from 'src/elements/TitleH2'
import { gql } from 'src/graphql'
import { BackIcon } from 'src/layout/BackIcon'
import { NavigationSlotContent } from 'src/layout/NavigationSlot'
import { Details } from './Details'
import { Status } from './Status'

const Header = styled.div`
  margin-bottom: 60px;
`

const StyledBackIcon = styled(BackIcon)`
  display: block;

  @media ${device.mobile} {
    display: none;
  }
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
`

const ContentWrapper = styled.div`
  width: 75%;

  @media ${device.tablet} {
    width: 100%;
  }
`

export function ScreenPage() {
  const { t } = useTranslation()

  const { id } = useParams<'id'>()

  if (id === undefined) {
    throw new Error('Screen ID is required')
  }

  const screenQuery = useQuery(
    gql(/* GraphQL */ `
      query Screen($id: ID!) {
        screen(id: $id) {
          name
          ...ScreenDetailsFragment
          ...PresenceFragment
        }
      }
    `),
    { variables: { id } }
  )

  const showScreenStatus = useFlag('show-screen-presence-status-77')

  const screen = screenQuery.data?.screen

  if (!screen) {
    return null
  }

  return (
    <div>
      <NavigationSlotContent>
        <StyledBackIcon path="/screens" title={t('routes.screens')} />
      </NavigationSlotContent>
      <Header>
        {showScreenStatus ? (
          <>
            <TitleH2>
              {t('screens.edit.new_title', {
                name: screen.name,
              })}
            </TitleH2>

            <Status screen={screen} />
          </>
        ) : (
          <>
            <TitleH2>{t('screens.edit.title')}</TitleH2>
            <SubTitle>{screen.name}</SubTitle>
          </>
        )}
      </Header>
      <ContentContainer>
        <ContentWrapper>
          <Details screen={screen} />
        </ContentWrapper>
      </ContentContainer>
    </div>
  )
}
