import { useCallback } from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import styled from 'styled-components'
import { device } from 'src/device'
import { gql } from 'src/graphql'
import { useOrganizationId } from 'src/hooks/useOrganizationId'
import { withArray, withFragments } from 'src/libraries/graphql-fragments'
import { ScreenItemDeprecated } from './ScreenItemDeprecated'
import { AddScreen } from './add-screen/AddScreen'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`

const ScreenItemWrapper = styled.div`
  margin-bottom: 30px;
  padding: 0 15px;
  min-height: 220px;
  flex: 1 1 100%;
  max-width: ${100 / 3}%;

  @media ${device.tablet} {
    max-width: 50%;
  }

  @media ${device.mobile} {
    max-width: 100%;
  }
`

const StyledAddScreen = styled(AddScreen)`
  min-height: 220px;
`

export const ScreenListDeprecated = withFragments()(
  {
    screens: withArray(
      gql(/* GraphQL */ `
        fragment ScreenListDeprecatedFragment on Screen {
          id
          ...ScreenItemDeprecatedFragment
        }
      `)
    ),
  },
  function ScreenListDeprecated({ screens }) {
    const client = useApolloClient()
    const organizationId = useOrganizationId()

    const [addScreenMutate] = useMutation(
      gql(/* GraphQL */ `
        mutation addScreen($name: String!, $organizationId: String!) {
          addScreen(name: $name, organizationId: $organizationId) {
            id
            name
          }
        }
      `),
      {
        onCompleted: async () => {
          await client.refetchQueries({
            include: 'active',
          })
        },
      }
    )

    const addScreen = useCallback(
      async (name: string) =>
        addScreenMutate({
          variables: {
            name,
            organizationId,
          },
        }),
      [addScreenMutate, organizationId]
    )

    return (
      <Container>
        {screens.map((screen) => (
          <ScreenItemWrapper key={`screen-${screen.id}`}>
            <ScreenItemDeprecated screen={screen} />
          </ScreenItemWrapper>
        ))}
        <ScreenItemWrapper key={`add-screen`}>
          <StyledAddScreen onAddScreen={addScreen} />
        </ScreenItemWrapper>
      </Container>
    )
  }
)
