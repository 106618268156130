import { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Dashboard } from 'src/dashboard/Dashboard'
import { MediaLibraryScreen } from 'src/media/MediaLibraryScreen'
import { EnsureOnboarding } from 'src/onboarding/EnsureOnboarding'
import { Playlists } from 'src/playlists/Playlists'
import { EditPlaylist } from 'src/playlists/edit/EditPlaylist'
import { Screens } from 'src/screens/Screens'
import { useCan } from './auth/useCan'
import { useInitAbility } from './auth/useInitAbility'
import { Loader } from './components/Loader'
import { useToggleOpener } from './hooks/useToggleOpener'
import { Layout } from './layout/Layout'
import { Navigation } from './layout/Navigation'
import { SideMenu } from './layout/SideMenu'
import { OrganizationPage } from './organizations/OrganizationPage'
import { Organizations } from './organizations/Organizations'
import { ScreensPage } from './screens/ScreensPage'
import { PairScreen } from './screens/pairing/PairScreen'
import { ScreenPage } from './screens/single/ScreenPage'

export function LoggedInRoutes() {
  const [showSidebar, toggleSidebar, closeSidebar] = useToggleOpener()

  const { pathname } = useLocation()
  useEffect(() => {
    closeSidebar()
  }, [pathname, closeSidebar])

  const { loaded } = useInitAbility()

  const can = useCan()

  if (!loaded) {
    return <Loader />
  }

  return (
    <EnsureOnboarding>
      <Layout
        sidebar={<SideMenu onCloseSidebar={closeSidebar} />}
        showSidebar={showSidebar}
        onHideSidebar={closeSidebar}
      >
        <Routes>
          <Route
            path="/"
            element={<Navigation onToggleSidebar={toggleSidebar} />}
          >
            <Route index element={<Navigate to="/dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="slideshows">
              <Route index element={<Playlists />} />
              <Route path=":id" element={<EditPlaylist />} />
              <Route path=":id/add" element={<EditPlaylist />} />
              <Route path=":id/edit" element={<EditPlaylist />} />
            </Route>
            <Route path="screens" element={<ScreensPage />}>
              <Route index element={<Screens />} />
              <Route path=":id" element={<ScreenPage />}>
                <Route path="pair" element={<ScreenPage />} />
              </Route>
              <Route path="pair" element={<PairScreen />} />
              <Route path="add" element={<Screens />} />
              <Route path="play" element={<Screens />} />
              <Route path="pair-old" element={<Screens />} />
            </Route>
            <Route path="media">
              <Route index element={<MediaLibraryScreen />} />
              <Route path=":id" element={<MediaLibraryScreen />} />
            </Route>
            {can('listAll', 'Organization') && (
              <Route path="organizations">
                <Route index element={<Organizations />} />
                <Route path=":id" element={<OrganizationPage />} />
              </Route>
            )}
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Route>
        </Routes>
      </Layout>
    </EnsureOnboarding>
  )
}
