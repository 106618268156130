import { useCallback, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import axios from 'axios'
import { gql } from 'src/graphql'
import { useOrganizationId } from 'src/hooks/useOrganizationId'

type Progresses = {
  [id: string]: number
}

const getUploadMediaRequest = gql(/* GraphQL */ `
  query uploadMediaRequest(
    $filename: String!
    $contentType: String!
    $organizationId: String!
  ) {
    uploadMediaRequest(
      filename: $filename
      contentType: $contentType
      organizationId: $organizationId
    ) {
      url
      key
      fields {
        key
        value
      }
    }
  }
`)

const onMediaChanged = gql(/* GraphQL */ `
  subscription onMediaChanged($mediaId: ID!) {
    onMediaChanged(id: $mediaId) {
      id
      url
      thumbnailUrl
    }
  }
`)

export function useUpload(onFile: (mediaId: string) => void) {
  const client = useApolloClient()

  const organizationId = useOrganizationId()

  const [progresses, setProgresses] = useState<Progresses>({})

  const uploadFile = useCallback(
    async (file: File) => {
      const {
        data: { uploadMediaRequest },
      } = await client.query({
        query: getUploadMediaRequest,
        variables: {
          filename: file.name,
          contentType: file.type,
          organizationId,
        },
      })

      const mediaId = uploadMediaRequest.key

      onFile(mediaId)

      const mediaChangedSubscriptionQuery = client.subscribe({
        query: onMediaChanged,
        variables: {
          mediaId,
        },
      })

      const mediaChangedsubscription = mediaChangedSubscriptionQuery.subscribe({
        next: () => {
          mediaChangedsubscription.unsubscribe()
          const { cache } = client
          cache.modify({
            id: cache.identify({
              __typename: 'Query',
            }),
            fields: {
              getAllMedia(_, { DELETE }) {
                return DELETE
              },
            },
          })
        },
      })

      const formData = new FormData()

      uploadMediaRequest.fields.forEach((field) => {
        formData.append(field.key, field.value)
      })
      formData.append('file', file)

      await axios.post(uploadMediaRequest.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          setProgresses((lastState) => ({
            ...lastState,
            [mediaId]: Math.round(
              (100 * progressEvent.loaded) / progressEvent.total
            ),
          }))
        },
      })
    },
    [client, onFile, organizationId]
  )

  const uploadFiles = useCallback(
    async (files: File[]) => {
      await Promise.all(files.map(uploadFile))
    },
    [uploadFile]
  )

  const resetProgress = useCallback(() => {
    setProgresses({})
  }, [setProgresses])

  const fileCount = Object.values(progresses).length
  const finishedFileCount = Object.values(progresses).filter(
    (progress) => progress === 100
  ).length
  const progress =
    fileCount > 0
      ? Math.round(
          Object.values(progresses).reduce(
            (sum, progress) => sum + progress,
            0
          ) / fileCount
        )
      : null

  return {
    uploadFiles,
    fileCount,
    finishedFileCount,
    progress,
    resetProgress,
  }
}
