import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { ResultOf } from '@graphql-typed-document-node/core'
import { gql } from 'src/graphql'
import { useOrganizationId } from 'src/hooks/useOrganizationId'
import { useUpload } from './useUpload'

const getAllMediaQuery = gql(/* GraphQL */ `
  query getAllMedia($organizationId: String!) {
    getAllMedia(organizationId: $organizationId) {
      id
      url
      thumbnailUrl
      created
    }
  }
`)

type MediaFile = ResultOf<typeof getAllMediaQuery>['getAllMedia'][number]

type OptimisticMediaFile =
  | MediaFile
  | (Partial<MediaFile> & Pick<MediaFile, 'id'>)

export function useMediaLibrary() {
  const organizationId = useOrganizationId()

  const allMediaQuery = useQuery(getAllMediaQuery, {
    variables: {
      organizationId,
    },
  })

  const uploadedFiles = allMediaQuery.data?.getAllMedia

  const [uploadingFiles, setUploadingFiles] = useState<OptimisticMediaFile[]>(
    []
  )

  const addFile = useCallback(
    (mediaId: string) =>
      setUploadingFiles((files) => [
        ...files,
        {
          id: mediaId,
        },
      ]),
    [setUploadingFiles]
  )

  useEffect(() => {
    setUploadingFiles((files) =>
      files.filter(
        (file) =>
          !uploadedFiles?.find((uploadedFile) => uploadedFile.id === file.id)
      )
    )
  }, [setUploadingFiles, uploadedFiles])

  const files = uploadedFiles
    ? [
        ...uploadingFiles.filter(
          (file) =>
            !uploadedFiles.find((uploadedFile) => uploadedFile.id === file.id)
        ),
        ...uploadedFiles,
      ]
    : uploadingFiles

  const { uploadFiles, fileCount, finishedFileCount, progress, resetProgress } =
    useUpload(addFile)

  return {
    isLoaded: allMediaQuery.data !== undefined,
    uploadFiles,
    files,
    fileCount,
    finishedFileCount,
    progress,
    resetProgress,
  }
}
