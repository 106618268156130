import { useSubscription } from '@apollo/client'
import { Outlet } from 'react-router-dom'
import { gql } from 'src/graphql'
import { useOrganizationId } from 'src/hooks/useOrganizationId'

export function ScreensPage() {
  const organizationId = useOrganizationId()

  useSubscription(
    gql(/* GraphQL */ `
      subscription OnScreenChanged($organizationId: ID!) {
        onScreenChanged(organizationId: $organizationId) {
          id
          presence {
            status
            lastActivity
          }
        }
      }
    `),
    {
      variables: {
        organizationId,
      },
    }
  )

  return <Outlet />
}
