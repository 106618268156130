import { getDefaultStore } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

export const selectedOrganizationIdAtom = atomWithStorage<string | null>(
  'selectedOrganizationId',
  null
)

export function resetSelectedOrganizationId() {
  getDefaultStore().set(selectedOrganizationIdAtom, null)
}
