import { useAtomValue } from 'jotai'
import { selectedOrganizationIdAtom } from 'src/organizations/selectedOrganizationIdAtom'

export function useOrganizationId(): string {
  const selectedOrganizationId = useAtomValue(selectedOrganizationIdAtom)

  if (selectedOrganizationId === null) {
    throw new Error('No organization selected')
  }

  return selectedOrganizationId
}
