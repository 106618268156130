import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { faPlus, faUserPlus } from '@fortawesome/pro-regular-svg-icons'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AnimatingModal } from 'src/elements/AnimatingModal'
import { Button } from 'src/elements/Button'
import { FormGroup } from 'src/elements/form/FormGroup'
import { Input } from 'src/elements/form/Input'
import { Label } from 'src/elements/form/Label'
import { gql } from 'src/graphql'
import { useToggleOpener } from 'src/hooks/useToggleOpener'

const Wrapper = styled.div`
  max-width: 400px;
`

const FormWrapper = styled.form`
  padding-top: 20px;
`

interface InviteUserFormInput {
  email: string
}

interface Props {
  organizationId: string
}
export function InviteUserButton({ organizationId }: Props) {
  const { t } = useTranslation()

  const [createModalOpened, toggleCreateModal, closeCreateModal] =
    useToggleOpener()

  const { handleSubmit, register, reset } = useForm<InviteUserFormInput>({
    defaultValues: {
      email: '',
    },
  })

  const [inviteUserToOrganization] = useMutation(
    gql(/* GraphQL */ `
      mutation inviteUserToOrganization(
        $organizationId: String!
        $email: String!
      ) {
        inviteUserToOrganization(
          organizationId: $organizationId
          email: $email
        ) {
          id
          name
        }
      }
    `),
    {
      refetchQueries: ['allOrganizations'],
      onCompleted: () => {
        closeCreateModal()
      },
    }
  )

  const onFormSubmit = useCallback(
    async ({ email }: InviteUserFormInput) => {
      await inviteUserToOrganization({
        variables: {
          email,
          organizationId,
        },
      })

      reset()
    },
    [inviteUserToOrganization, organizationId, reset]
  )

  return (
    <>
      <Button
        label={t('organizations.invite.button.title')}
        icon={faUserPlus}
        onClick={toggleCreateModal}
      />
      <AnimatingModal
        show={createModalOpened}
        showCloseButton
        onClose={closeCreateModal}
      >
        <Wrapper>
          <FormWrapper onSubmit={handleSubmit(onFormSubmit)}>
            <FormGroup>
              <Label htmlFor="email">{t('organizations.invite.email')}</Label>
              <Input {...register('email')} />
            </FormGroup>
            <Button
              as="button"
              fullWidth
              label={t('organizations.invite.submit')}
              icon={faPlus}
              type="submit"
            />
          </FormWrapper>
        </Wrapper>
      </AnimatingModal>
    </>
  )
}
