import { Connected, Disconnected } from 'src/components/icons/Icons'
import { ConnectionStatus } from 'src/graphql/graphql'

interface Props {
  status?: string
}

export function StatusIcon({ status }: Props) {
  const Icon = status === ConnectionStatus.Connected ? Connected : Disconnected
  return <Icon data-test={`status-icon-${status}`} />
}
