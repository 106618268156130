import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { device } from 'src/device'
import { SubTitle } from 'src/elements/SubTitle'
import { TitleH2 } from 'src/elements/TitleH2'
import { gql } from 'src/graphql'
import { BackIcon } from 'src/layout/BackIcon'
import { NavigationSlotContent } from 'src/layout/NavigationSlot'
import { InviteUserButton } from './InviteUserButton'
import { RegistrationStatus } from './RegistrationStatus'

const Header = styled.div`
  margin-bottom: 60px;
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.darkBlue};
`

const ContentWrapper = styled.div`
  width: 75%;

  @media ${device.tablet} {
    width: 100%;
  }
`

const InviteButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
  table-layout: fixed;

  td,
  th {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  th {
    color: ${({ theme }) => theme.colors.blue};
  }
`

const StyledBackIcon = styled(BackIcon)`
  display: block;

  @media ${device.mobile} {
    display: none;
  }
`

export function OrganizationPage() {
  const { id } = useParams<'id'>()
  const { t } = useTranslation()

  if (id === undefined) {
    throw new Error('Organization ID is required')
  }

  const organizationQuery = useQuery(
    gql(/* GraphQL */ `
      query Organization($id: ID!) {
        organization(id: $id) {
          id
          name
          users {
            id
            email
            registrationStatus
          }
        }
      }
    `),
    {
      variables: { id },
    }
  )

  const organization = organizationQuery.data?.organization

  if (!organization) {
    return null
  }

  return (
    <div>
      <NavigationSlotContent>
        <StyledBackIcon
          path="/organizations"
          title={t('routes.organizations')}
        />
      </NavigationSlotContent>
      <Header>
        <TitleH2>{organization.name}</TitleH2>
        <SubTitle>{`${organization.users.length} ${organization.users.length > 1 ? 'members' : 'member'}`}</SubTitle>
      </Header>
      <ContentContainer>
        <ContentWrapper>
          <InviteButtonWrapper>
            <InviteUserButton organizationId={organization.id} />
          </InviteButtonWrapper>
          <Table>
            <thead>
              <tr>
                <th>{t('users.userId')}</th>
                <th>{t('users.email')}</th>
                <th>{t('users.registrationStatus.title')}</th>
              </tr>
            </thead>
            <tbody>
              {organization.users.map((user) => (
                <tr key={user.id}>
                  <td title={user.id}>{user.id}</td>
                  <td title={user.email}>{user.email}</td>
                  <td>
                    <RegistrationStatus regStatus={user.registrationStatus} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ContentWrapper>
      </ContentContainer>
    </div>
  )
}
