import {
  ChangeEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { usePrevious } from 'react-use'

const BACKSPACE = 8

function useInput(
  value: string,
  setValue: (value: string) => void,
  onBack?: () => void,
  onDone?: () => void
) {
  const ref = useRef<HTMLInputElement>(null)

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setValue(e.target.value)
    },
    [setValue]
  )

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (e.keyCode === BACKSPACE && value.length === 0 && onBack) {
        onBack()
      }
    },
    [onBack, value]
  )

  useEffect(() => {
    if (value.length === 3 && onDone) {
      onDone()
    }
  }, [onDone, value])

  const focus = useCallback(() => {
    ref.current?.focus()
  }, [ref])

  return {
    ref,
    value,
    onChange,
    onKeyDown,
    focus,
  }
}

export function useCode() {
  const [code, setCode] = useState('')

  const setNThValue = useCallback(
    (n: number, value: string) => {
      setCode((code) => {
        const newCode = code.slice(0, n) + value + code.slice(n + 1)
        return newCode.slice(0, 4)
      })
    },
    [setCode]
  )

  const onBack = useCallback(() => {
    setCode((code) => code.slice(0, -1))
  }, [setCode])

  const inputProps = [
    useInput(code.slice(0, 1), (value) => setNThValue(0, value), onBack),
    useInput(code.slice(1, 2), (value) => setNThValue(1, value), onBack),
    useInput(code.slice(2, 3), (value) => setNThValue(2, value), onBack),
    useInput(code.slice(3, 4), (value) => setNThValue(3, value), onBack),
  ] as const

  const [firstProps, secondProps, thirdProps, fourthProps] = inputProps

  const focusFirst = firstProps.focus
  const focusSecond = secondProps.focus
  const focusThird = thirdProps.focus
  const focusFourth = fourthProps.focus

  const previousCode = usePrevious(code)

  useEffect(() => {
    const focusMap = [focusFirst, focusSecond, focusThird, focusFourth] as const

    const currentLength = code.length
    const previousLength = previousCode?.length ?? 0

    const codeLengthChanged = currentLength !== previousLength

    if (codeLengthChanged) {
      focusMap[currentLength]?.()
    }
  }, [code, previousCode, focusFirst, focusSecond, focusThird, focusFourth])

  return {
    code,
    firstProps,
    secondProps,
    thirdProps,
    fourthProps,
  }
}
